let maior_scroll = 0;
let menor_scroll = 0;
$(window).scroll(() => {
  if (
    document.body.scrollTop > 120 ||
    document.documentElement.scrollTop > 120
  ) {
    $("#bar-top").addClass("scroll-hidden");
    $(".bg-menu").addClass("menu-scroll");
    $(".razao-select").addClass("razao-scroll-on");
    // $(".container-fluid").addClass("menu-bar-on-scroll");
  } else {
    $("#bar-top").removeClass("scroll-hidden");
    $(".bg-menu").removeClass("menu-scroll");
    $(".razao-select").removeClass("razao-scroll-on");

    // $(".container-fluid").removeClass("menu-bar-on-scroll");
  }

  if (
    document.body.scrollTop > 145 ||
    document.documentElement.scrollTop > 145
  ) {
    $(".navbar-fixed-top").addClass("scroll-hidden");
  } else {
    $(".navbar-fixed-top").removeClass("scroll-hidden");
  }

  // SCROLL MENU
  if (maior_scroll < window.pageYOffset) {
    maior_scroll = window.pageYOffset;
  } else if (menor_scroll > window.pageYOffset) {
    menor_scroll = window.pageYOffset;
  } else if (menor_scroll < maior_scroll) {
    $(".bg-menu").removeClass("menu-scroll");
    $(".navbar-fixed-top").removeClass("scroll-hidden");
    maior_scroll = window.pageYOffset;
  }
});

export const addURL = (codigo, codigo_url) => {
  // DECODIFICA A URL E CONVERTE PARA ARRAY
  const array = codigo_url ? decodeURIComponent(codigo_url).split(",") : [];

  // ADICIONA O CÓDIGO DO FORNECEDOR NA URL DE ACORDO COM A LISTA
  array.push(codigo.toString());

  // CASO EXISTA CÓDIGO REPETIDO É REMOVIDO
  const novo_array = array.filter((t, i) => array.indexOf(t) == i);

  // CODIFICA A URL
  const url = encodeURIComponent(novo_array.join(","));
  return url;
};

export const removeURL = (codigo, codigo_url) => {
  // DECODIFICA A URL E CONVERTE PARA ARRAY
  let array = decodeURIComponent(codigo_url).split(",");

  // REMOVENDO ITEM DO ARRAY
  array = array.filter(
    (obj, index) => obj !== codigo.toString() && obj.toString().trim() !== ""
  );

  // CODIFICA A URL
  const url = encodeURIComponent(array.join(","));
  return url;
};

export const encodeURL = (array) => encodeURIComponent(array.join(","));

export const decodeURL = (codigo_url) => {
  if (!codigo_url) return null;

  let retorno = [];

  // DECODIFICA A URL E CONVERTE PARA ARRAY
  const params = decodeURIComponent(codigo_url);

  if (typeof params !== "undefined") {
    retorno = params.split(",");

    // REMOVENDO OS ESPAÇOS EM BRANCO, POIS O SPLIT DEIXA 1 ESPAÇO EM BRANCO CASO A ARRAY FIQUE VAZIA.
    retorno = retorno.filter((obj, index) => obj.toString().trim() !== "");
  }

  return retorno;
};

export const paramExist = (param) => {
  const tp = typeof param;

  if (tp == "undefined") return false;

  if (tp == "number") {
    if (tp == 0) {
      return false;
    }
  }

  if (tp == "string") {
    if (param == "0" || param.trim() == "") {
      return false;
    }
  }

  if (tp == "object" || tp == "array") {
    if (param.length == 0) {
      return false;
    }
  }

  return true;
};

export const debounceCustom = function debounce(func, timeout = 600) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const mascaraTelefone = (numero) => {
  const ligacao_gratuita =
    numero != undefined
      ? numero.replace(/[^0-9]/g, "").substring(0, 4)
      : undefined;

  if (
    numero != undefined &&
    numero.length == 11 &&
    (ligacao_gratuita == "0800" || ligacao_gratuita == "0300")
  ) {
    return numero.replace(/(\d{4})(\d{3})(\d{4})/g, "$1-$2-$3");
  }
  if (numero != undefined && numero.length == 10) {
    return numero.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
  }
  if (numero != undefined) {
    return numero.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, "($1) $2 $3-$4");
  }
};

export const mascaraCpfCnpj = (numero) => {
  if (numero != undefined && numero.length == 11) {
    return numero.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }
  if (numero != undefined) {
    return numero.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }
};

export const mascaraHoraMinuto = (hora) => {
  const hora_minuto =
    hora != undefined ? hora.replace(/[^0-9]/g, "").substring(0, 4) : undefined;

  if (hora_minuto != undefined) {
    return hora_minuto.replace(/(\d{2})(\d{2})/g, "$1:$2h");
  }
};

export const marcaraCep = (numero) => {
  if (numero != undefined) {
    return numero.replace(/(\d{2})(\d{3})(\d{3})/g, "$1$2-$3");
  }
};

export const mascaraValor = (value, decimal = 2) => {
  if (value != null) {
    value = value.toString();
    value = value.replace(",", ".");

    const $retorno = Number(value)
      .toFixed(decimal)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    return $retorno != "NaN" ? $retorno : "0,00";
  }
  return "0,00";
};

export const roundValor = (value, decimal = 2) => {
  if (!roundValor) return roundValor;
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const getProdUrlDesc = (description) => {
  if (!description) return "";

  return description
    .normalize("NFD")
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
};

export const toTitleCase = (text) => {
  // console.log(text);
  if (!text || typeof text !== "string") return text;

  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const DATAMatrix = (r) => {
  const e = [];
  let n = 0;
  let t = 0;
  const f = (r, n) => {
    (e[n] = e[n] || []), (e[n][r] = 1);
  };
  const o = (r) => {
    for (var e = [], n = r.length, t = 0; t < n; t++) {
      const f = r.charCodeAt(t);
      const o = t + 1 < n ? r.charCodeAt(t + 1) : 0;
      f > 47 && f < 58 && o > 47 && o < 58
        ? (e.push(10 * (f - 48) + o + 82), t++)
        : f > 127
        ? (e.push(235), e.push((f - 127) & 255))
        : e.push(f + 1);
    }
    return e;
  };
  const h = (r, e) => {
    let n;
    let t;
    let f = 0;
    let h = 0;
    const i = r.length;
    let u = [e[0]];
    const a = (r) => {
      (h = 40 * h + r),
        f++ == 2 && (u.push(++h >> 8), u.push(255 & h), (f = h = 0));
    };
    for (n = 0; n < i && (f != 0 || n != i - 1); n++) {
      let s = r.charCodeAt(n);
      for (
        s > 127 && u[0] != 238 && (a(1), a(30), (s -= 128)), t = 1;
        s > e[t];
        t += 3
      );
      const l = e[t + 1];
      if (l == 8 || (l == 9 && f == 0 && n == i - 1)) return [];
      if (l < 5 && f == 2 && n == i - 1) break;
      l < 5 && a(l), a(s - e[t + 2]);
    }
    return (
      f == 2 && u[0] !== 238 && a(0),
      u.push(254),
      (f > 0 || n < i) && (u = u.concat(o(r.substr(n - f)))),
      u
    );
  };
  const i = (r, e) => {
    r = unescape(encodeURI(r));
    let i = o(r);
    let u = i.length;
    let a = h(
      r,
      [
        230, 31, 0, 0, 32, 9, 29, 47, 1, 33, 57, 9, 44, 64, 1, 43, 90, 9, 51,
        95, 1, 69, 127, 2, 96, 255, 1, 0,
      ]
    );
    let s = a.length;
    s > 0 && s < u && ((i = a), (u = s)),
      (s = (a = h(
        r,
        [
          239, 31, 0, 0, 32, 9, 29, 47, 1, 33, 57, 9, 44, 64, 1, 43, 90, 2, 64,
          95, 1, 69, 122, 9, 83, 127, 2, 96, 255, 1, 0,
        ]
      )).length) > 0 &&
        s < u &&
        ((i = a), (u = s)),
      (s = (a = h(
        r,
        [
          238, 12, 8, 0, 13, 9, 13, 31, 8, 0, 32, 9, 29, 41, 8, 0, 42, 9, 41,
          47, 8, 0, 57, 9, 44, 64, 8, 0, 90, 9, 51, 255, 8, 0,
        ]
      )).length) > 0 &&
        s < u &&
        ((i = a), (u = s)),
      (s = (a = ((r) => {
        for (
          var e,
            n = r.length,
            t = (n + 1) & -4,
            f = 0,
            h = t > 0 ? [240] : [],
            i = 0;
          i < t;
          i++
        ) {
          if (i < t - 1) {
            if ((e = r.charCodeAt(i)) < 32 || e > 94) return [];
          } else e = 31;
          (f = 64 * f + (63 & e)),
            (3 & i) == 3 &&
              (h.push(f >> 16),
              h.push((f >> 8) & 255),
              h.push(255 & f),
              (f = 0));
        }
        return t > n ? h : h.concat(o(r.substr(t == 0 ? 0 : t - 1)));
      })(r)).length) > 0 &&
        s < u &&
        ((i = a), (u = s)),
      (s = (a = ((r) => {
        const e = [231];
        const n = r.length;
        n > 250 && e.push((37 + ((n / 250) | 0)) & 255),
          e.push(((n % 250) + ((149 * (e.length + 1)) % 255) + 1) & 255);
        for (let t = 0; t < n; t++)
          e.push((r.charCodeAt(t) + ((149 * (e.length + 1)) % 255) + 1) & 255);
        return e;
      })(r)).length) > 0 &&
        s < u &&
        ((i = a), (u = s));
    let l;
    let c;
    let p;
    let v;
    let g;
    let d;
    let w;
    let A;
    let m = 1;
    let C = 1;
    let b = -1;
    let y = 1;
    const M = new Array(70);
    const x = new Array(70);
    const z = new Array(256);
    const E = new Array(255);
    if (e && u < 50) {
      a = [16, 7, 28, 11, 24, 14, 32, 18, 32, 24, 44, 28];
      do {
        s = ((c = a[++b]) * (l = 6 + (12 & b))) / 8;
      } while (s - a[++b] < u);
      c > 25 && (m = 2);
    } else {
      (c = l = 6),
        (g = 2),
        (a = [
          5, 7, 10, 12, 14, 18, 20, 24, 28, 36, 42, 48, 56, 68, 84, 112, 144,
          192, 224, 272, 336, 408, 496, 620,
        ]);
      do {
        if (++b == a.length) return [0, 0];
        c > 11 * g && (g = (4 + g) & 12), (s = ((c = l += g) * l) >> 3);
      } while (s - a[b] < u);
      c > 27 && (C = m = 2 * ((c / 54) | 0) + 2),
        s > 255 && (y = 2 * (s >> 9) + 2);
    }
    for (
      p = c / m, v = l / C, u < s - (A = a[b]) && (i[u++] = 129);
      u < s - A;

    )
      i[u++] = (((149 * u) % 253) + 130) % 254;
    for (A /= y, b = 1, g = 0; g < 255; g++)
      (E[g] = b), (z[b] = g), (b += b) > 255 && (b ^= 301);
    for (M[A] = 0, g = 1; g <= A; g++)
      for (M[(b = A - g)] = 1; b < A; b++)
        M[b] = M[b + 1] ^ E[(z[M[b]] + g) % 255];
    for (d = 0; d < y; d++) {
      for (g = 0; g <= A; g++) x[g] = 0;
      for (g = d; g < u; g += y)
        for (b = 0, j = x[0] ^ i[g]; b < A; b++)
          x[b] = x[b + 1] ^ (j ? E[(z[M[b]] + z[j]) % 255] : 0);
      for (g = 0; g < A; g++) i[u + d + g * y] = x[g];
    }
    for (g = 0; g < l + 2 * C; g += v + 2)
      for (b = 0; b < c + 2 * m; b++) f(b, g + v + 1), (1 & b) == 0 && f(b, g);
    for (g = 0; g < c + 2 * m; g += p + 2)
      for (b = 0; b < l; b++)
        f(g, b + 2 * ((b / v) | 0) + 1),
          (1 & b) == 1 && f(g + p + 1, b + 2 * ((b / v) | 0));
    for (
      A = 2,
        d = 0,
        w = 4,
        y = [0, 0, -1, 0, -2, 0, 0, -1, -1, -1, -2, -1, -1, -2, -2, -2],
        g = 0;
      g < s;
      w -= A, d += A
    ) {
      if (w == l - 3 && d == -1)
        a = [
          c,
          6 - l,
          c,
          5 - l,
          c,
          4 - l,
          c,
          3 - l,
          c - 1,
          3 - l,
          3,
          2,
          2,
          2,
          1,
          2,
        ];
      else if (w == l + 1 && d == 1 && (7 & c) == 0 && (7 & l) == 6)
        a = [
          c - 2,
          -l,
          c - 3,
          -l,
          c - 4,
          -l,
          c - 2,
          -1 - l,
          c - 3,
          -1 - l,
          c - 4,
          -1 - l,
          c - 2,
          -2,
          -1,
          -2,
        ];
      else {
        if (w == 0 && d == c - 2 && 3 & c) continue;
        if (w < 0 || d >= c || w >= l || d < 0)
          for (
            w += 2 + (A = -A) / 2, d += 2 - A / 2;
            w < 0 || d >= c || w >= l || d < 0;

          )
            (w -= A), (d += A);
        if (w == l - 2 && d == 0 && 3 & c)
          a = [
            c - 1,
            3 - l,
            c - 1,
            2 - l,
            c - 2,
            2 - l,
            c - 3,
            2 - l,
            c - 4,
            2 - l,
            0,
            1,
            0,
            0,
            0,
            -1,
          ];
        else if (w == l - 2 && d == 0 && (7 & c) == 4)
          a = [
            c - 1,
            5 - l,
            c - 1,
            4 - l,
            c - 1,
            3 - l,
            c - 1,
            2 - l,
            c - 2,
            2 - l,
            0,
            1,
            0,
            0,
            0,
            -1,
          ];
        else {
          if (w == 1 && d == c - 1 && (7 & c) == 0 && (7 & l) == 6) continue;
          a = y;
        }
      }
      for (u = i[g++], b = 0; u > 0; b += 2, u >>= 1)
        if (1 & u) {
          var j = d + a[b];
          let k = w + a[b + 1];
          j < 0 && ((j += c), (k += 4 - ((c + 4) & 7))),
            k < 0 && ((k += l), (j += 4 - ((l + 4) & 7))),
            f(j + 2 * ((j / p) | 0) + 1, k + 2 * ((k / v) | 0) + 1);
        }
    }
    for (g = c; 3 & g; g--) f(g, g);
    (n = c + 2 * m), (t = l + 2 * C);
  };
  return (() => {
    function f(r) {
      return /^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(r);
    }
    function o(r, e) {
      for (const n in ((r = document.createElementNS(g, r)), e || {}))
        r.setAttribute(n, e[n]);
      return r;
    }
    let h;
    let u;
    let a;
    let s;
    let l;
    let c;
    let p;
    const v = Math.abs;
    var g = "http://www.w3.org/2000/svg";
    let d = "";
    const w = typeof r === "string" ? { msg: r } : r || {};
    const A = w.pal || ["#000"];
    const m = v(w.dim) || 256;
    const C = [1, 0, 0, 1, (p = (p = v(w.pad)) > -1 ? p : 2), p];
    var b = f((b = A[0])) ? b : "#000";
    var y = f((y = A[1])) ? y : 0;
    const M = w.vrb ? 0 : 1;
    for (i(w.msg || "", w.rct), l = n + 2 * p, c = t + 2 * p, a = t; a--; )
      for (s = 0, u = n; u--; )
        e[a][u] &&
          (M
            ? (s++,
              e[a][u - 1] || ((d += `M${u},${a}h${s}v1h-${s}v-1z`), (s = 0)))
            : (d += `M${u},${a}h1v1h-1v-1z`));
    return (
      (h = o("svg", {
        viewBox: [0, 0, l, c].join(" "),
        width: ((m / c) * l) | 0,
        height: m,
        fill: b,
        "shape-rendering": "crispEdges",
        xmlns: g,
        version: "1.1",
      })),
      y && h.appendChild(o("path", { fill: y, d: `M0,0v${c}h${l}V0H0Z` })),
      h.appendChild(o("path", { transform: `matrix(${C})`, d })),
      h
    );
  })();
};
