import _sum from "lodash/sum";
import state from "../../vuex/state";

export const updateStageCampanha = (campanha) => {
  const orderItems = state.carrinhoAtual;

  let vlrTotalPedido = 0;
  let qtdTotalPedido = 0;

  let campanhaAux = null;

  let valorCreditoDisponivel = 0;

  if (campanha.situacao_credito.length) {
    valorCreditoDisponivel = +campanha.situacao_credito[0].valor_restante;
  } else {
    valorCreditoDisponivel = campanha.valor_max_prod_participantes;
  }

  const gruposAtivacaoStatus = [];

  const percentMaxPerGroup = 100 / campanha.grupos_ativacao.length;

  let percentCampanha = 0;

  let qtdMinProdutosAtivacao = 0;

  let grupoProximoAtivado = {
    id: "",
    percent: 0,
  };

  // calcula os valores totais do pedido

  campanha.grupos_ativacao.forEach((grupo, indexGrupo) => {
    const grupoCodProdutos = grupo.produtos_ativacao.map(
      (produto_ativacao) => produto_ativacao.integracao_id
    );

    const grupoOrderItems = orderItems.filter((orderItem) =>
      grupoCodProdutos.includes(orderItem.integracao_id.toString())
    );

    const grupoOrderItemsQtd = grupoOrderItems.length
      ? _sum(grupoOrderItems.map((orderItem) => Number(orderItem.qtd)))
      : 0;

    const grupoOrderItemsValorTotal = grupoOrderItems.length
      ? _sum(
          grupoOrderItems.map(
            (orderItem) => Number(orderItem.qtd) * Number(orderItem.valor)
          )
        )
      : 0;
    valorCreditoDisponivel -= grupoOrderItemsValorTotal;

    vlrTotalPedido += grupoOrderItemsValorTotal;
    qtdTotalPedido += grupoOrderItemsQtd;
  });

  // calcula por grupo de ativacao
  campanha.grupos_ativacao.forEach((grupo, indexGrupo) => {
    qtdMinProdutosAtivacao = 0;

    let qtdProdutosAtivados = 0;
    let vlrProdutosAtivados = 0;

    grupo.produtos_ativacao.forEach((produto_ativacao) => {
      let orderItem = orderItems.find(
        (orderItem) => orderItem.integracao_id == produto_ativacao.integracao_id
      );

      if (!orderItem) {
        orderItem = {
          qtd: 0,
          valortotal: 0,
          valor: 0,
        };
      }

      const prodRules = {
        qtdMin: false,
        qtdMax: false,
        vlrMin: false,
        vlrMax: false,
      };
      if (orderItem.qtd >= produto_ativacao.qtd_unidades_min) {
        prodRules.qtdMin = true;
      }

      if (orderItem.qtd <= produto_ativacao.qtd_unidades_max) {
        prodRules.qtdMax = true;
      }

      if (
        orderItem.valor &&
        orderItem.valor * orderItem.qtd >= produto_ativacao.valor_minimo
      ) {
        prodRules.vlrMin = true;
      }

      if (
        orderItem.valor &&
        orderItem.valor * orderItem.qtd <= produto_ativacao.valor_maximo
      ) {
        prodRules.vlrMax = true;
      }

      if (Object.values(prodRules).every((e) => e) === true) {
        if (!produto_ativacao.qtd_unidades_min > 0) {
          qtdProdutosAtivados += orderItem.qtd;
        } else {
          qtdProdutosAtivados += produto_ativacao.qtd_unidades_min;
        }
      } else if (produto_ativacao.qtd_unidades_min > 0) {
        qtdProdutosAtivados += orderItem.qtd;
      }

      vlrProdutosAtivados += orderItem.valor * orderItem.qtd;

      if (produto_ativacao.qtd_unidades_min > 0) {
        qtdMinProdutosAtivacao += produto_ativacao.qtd_unidades_min;
      } else {
        qtdMinProdutosAtivacao = +grupo.qtd_un_grp_ati_min;
      }
    });

    const rules = [
      vlrTotalPedido >= campanha.valor_min_prod_participantes,
      vlrTotalPedido <= campanha.valor_max_prod_participantes,
      qtdTotalPedido >= campanha.qtd_min_prod_participantes,
      qtdTotalPedido <= campanha.qtd_max_prod_participantes,
      qtdProdutosAtivados >= qtdMinProdutosAtivacao,
      qtdProdutosAtivados <= grupo.qtd_un_grp_ati_max,
      vlrProdutosAtivados >= grupo.valor_minimo,
      vlrProdutosAtivados <= grupo.valor_maximo,
    ];

    // console.log(
    //   "vlrTotalPedido >= campanha.valor_min_prod_participantes",
    //   vlrTotalPedido >= campanha.valor_min_prod_participantes,
    //   "vlrTotalPedido <= campanha.valor_max_prod_participantes",
    //   vlrTotalPedido <= campanha.valor_max_prod_participantes,
    //   "qtdTotalPedido >= campanha.qtd_min_prod_participantes",
    //   qtdTotalPedido >= campanha.qtd_min_prod_participantes,
    //   "qtdTotalPedido <= campanha.qtd_max_prod_participantes",
    //   qtdTotalPedido <= campanha.qtd_max_prod_participantes,
    //   "qtdProdutosAtivados >= qtdMinProdutosAtivacao",
    //   qtdProdutosAtivados >= qtdMinProdutosAtivacao,
    //   "qtdProdutosAtivados <= grupo.qtd_un_grp_ati_max",
    //   qtdProdutosAtivados <= grupo.qtd_un_grp_ati_max,
    //   "vlrProdutosAtivados >= grupo.valor_minimo",
    //   vlrProdutosAtivados >= grupo.valor_minimo,
    //   "vlrProdutosAtivados <= grupo.valor_maximo",
    //   vlrProdutosAtivados <= grupo.valor_maximo
    // );
    const followRules = rules.every((e) => e);
    const newRuleStatus = followRules ? "Ativado" : "Desativado";

    // console.log(campanha, followRules);
    // console.log(
    //   "vlrTotalPedido",
    //   vlrTotalPedido,
    //   "campanha.valor_min_prod_participantes",
    //   campanha.valor_min_prod_participantes,
    //   "campanha.valor_max_prod_participantes",
    //   campanha.valor_max_prod_participantes,
    //   "qtdTotalPedido",
    //   qtdTotalPedido,
    //   "campanha.qtd_min_prod_participantes",
    //   campanha.qtd_min_prod_participantes,
    //   "campanha.qtd_max_prod_participantes",
    //   campanha.qtd_max_prod_participantes,
    //   "qtdProdutosAtivados",
    //   qtdProdutosAtivados,
    //   "grupo.qtd_un_grp_ati_max",
    //   grupo.qtd_un_grp_ati_max,
    //   "vlrProdutosAtivados",
    //   vlrProdutosAtivados,
    //   "grupo.valor_minimo",
    //   grupo.valor_minimo,
    //   "grupo.valor_maximo",
    //   grupo.valor_maximo,
    //   "qtdMinProdutosAtivacao",
    //   qtdMinProdutosAtivacao,
    //   "grupo",
    //   grupo
    // );
    grupo.statusAtivacao = newRuleStatus;
    campanha.grupos_ativacao[indexGrupo].statusAtivacao = newRuleStatus;

    const qtdGruposAtivos = campanha.grupos_ativacao.filter(
      (e) => e.statusAtivacao == "Ativado"
    ).length;

    const bonusAtivos = [];
    campanha.grupos_bonus.forEach((grupo_bonus) => {
      // console.log(
      //   "qtdGruposAtivos >= grupo_bonus.qtd_grupos_ativacao && valorCreditoDisponivel > 0",
      //   [
      //     qtdGruposAtivos,
      //     grupo_bonus.qtd_grupos_ativacao,
      //     valorCreditoDisponivel,
      //   ]
      // );
      if (
        qtdGruposAtivos >= grupo_bonus.qtd_grupos_ativacao &&
        valorCreditoDisponivel > 0
      ) {
        grupo_bonus.statusAtivacao = "Ativado";

        bonusAtivos.push({
          id: grupo_bonus.id,
          percent: Number(grupo_bonus.vlrdesconto),
          qtdCombos: grupo_bonus.qtd_grupos_ativacao,
          isActive: true,
          displayDiscount: grupo_bonus.display_discount,
        });
      } else {
        grupo_bonus.statusAtivacao = "Desativado";

        bonusAtivos.push({
          id: grupo_bonus.id,
          percent: Number(grupo_bonus.vlrdesconto),
          qtdCombos: grupo_bonus.qtd_grupos_ativacao,
          isActive: false,
          displayDiscount: grupo_bonus.display_discount,
        });
      }
    });

    let percentQtdMinimaGrupo =
      qtdProdutosAtivados > qtdMinProdutosAtivacao
        ? 100
        : (qtdProdutosAtivados * 100) / qtdMinProdutosAtivacao;
    percentQtdMinimaGrupo = (50 / 100) * (percentQtdMinimaGrupo / 100) * 100;

    let percentVlrMinimoGrupo =
      vlrProdutosAtivados > +grupo.valor_minimo
        ? 100
        : (vlrProdutosAtivados * 100) / +grupo.valor_minimo || 0;
    percentVlrMinimoGrupo = (50 / 100) * (percentVlrMinimoGrupo / 100) * 100;

    const percentGrupoAtual = percentQtdMinimaGrupo + percentVlrMinimoGrupo;

    const percentGrupoGeral =
      (percentMaxPerGroup / 100) * (percentGrupoAtual / 100) * 100;
    percentCampanha += percentGrupoGeral;
    gruposAtivacaoStatus[grupo.id] = {
      name: `Combo ${indexGrupo + 1} ${grupo.descricao
        .toLowerCase()
        .replace("combo", "")}`,
      qtdMinProd: qtdMinProdutosAtivacao || 1,
      qtdMaxProd: grupo.qtd_max_prod_participantes,
      qtdMinProdAtivados: qtdProdutosAtivados,
      vlrMinProd: +grupo.valor_minimo,
      vlrMaxProd: +grupo.valor_maximo,
      vlrMinProdAtivados: vlrProdutosAtivados,
      percent: percentGrupoAtual,
      vlrAtualProd: vlrTotalPedido,
      qtdAtualProd: qtdTotalPedido,
    };
    if (
      (percentGrupoAtual >= grupoProximoAtivado.percent &&
        percentGrupoAtual != 100) ||
      grupoProximoAtivado.percent == 100
    ) {
      grupoProximoAtivado = {
        id: grupo.id,
        percent: percentGrupoAtual,
      };
    }

    campanhaAux = {
      id: campanha.id,
      vlrMaxCredito: +campanha.valor_max_prod_participantes,
      vlrRestanteCredito:
        valorCreditoDisponivel > 0 ? valorCreditoDisponivel : 0,
      bonusAtivos,
      gruposAtivacaoStatus,
      percentAtual: percentCampanha,
      grupoProximoAtivado,
      bannerSrc: "https://img.dpcnet.com.br/banners/MONANGE-DESKTOPP.png",
    };
  });
  return campanhaAux;
};
