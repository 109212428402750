var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-produto",class:[_vm.prodClass, _vm.defaultClass],attrs:{"id":"item-produto"}},[_c('div',{staticClass:"produto-content col-lg-12 col-md-12 col-sm-12 col-xs-12 rounded-default"},[_c('div',{staticClass:"produto-content__image"},[_c('div',{staticClass:"flex flex-row justify-end"},[(_vm.quantidade > 0 && _vm.dadosPedido)?_c('div',{staticClass:"item-tag-info",on:{"click":function($event){return _vm.callOpenFloatingCart(true)}}},[_c('i',{staticClass:"fa fa-truck"}),_c('span',[_vm._v("Item no caminhão")])]):_vm._e(),(_vm.item.vlrdesconto)?_c('div',{staticClass:"discountBadge"},[_c('arrow-down-icon',{staticClass:"custom-class",attrs:{"size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.item.displayDescontoPct))])],1):_vm._e()]),(_vm.item.descricao)?_c('router-link',{staticStyle:{"flex":"1 1 auto","display":"flex"},attrs:{"tag":"a","to":{
          name: 'produtoRota',
          params: {
            codigo: _vm.item.integracao_id,
            id: _vm.item.cod_produto,
            filtroId: _vm.$route.query.filtroId,
          },
        },"title":_vm.item.descricao},nativeOn:{"click":function($event){return _vm.closeOverlay.apply(null, arguments)}}},[_c('div',{class:_vm.item.descricao.length > 65
              ? 'imgContainerProduto containerImg150'
              : 'imgContainerProduto'},[_c('div',{staticClass:"imagem",style:(_vm.hasVariations ? 'padding-bottom: 50px!important' : null)},[(_vm.srcPreview)?_c('img',{staticClass:"img img-responsive",attrs:{"src":_vm.srcPreview,"onerror":"this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'","alt":_vm.item.descricao,"loading":"lazy"}}):_c('ImageItem',{attrs:{"custom-class":"img img-responsive","source":_vm.item.src,"description":_vm.item.descricao}})],1)]),_c('div',{staticClass:"img-thumb"},[_c('i',{staticClass:"fa fa-camera",attrs:{"aria-hidden":"true"},on:{"mouseover":function($event){_vm.pop_active = !_vm.pop_active},"mouseout":function($event){_vm.pop_active = !_vm.pop_active}}}),_c('Popover',{attrs:{"active":_vm.pop_active}},[_c('img',{staticClass:"img-responsive img-pop",attrs:{"src":_vm.item.src,"oneerror":"this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'","alt":"","loading":"lazy"}})])],1)]):_vm._e(),(_vm.hasVariations)?_c('product-variant-list',{ref:"variants",staticStyle:{"margin-top":"-50px","z-index":"2"},attrs:{"product-variant":_vm.item.grupo,"is-preview":true,"current-product-id":_vm.item.integracao_id},on:{"imagePreview":function($event){_vm.srcPreview = $event},"product-selected":function($event){_vm.variantSelectedProductId = $event}}}):_vm._e()],1),_c('div',{staticClass:"informacoes"},[(_vm.item.ads)?_c('div',{staticClass:"sponsored-label"},[_c('span',{staticClass:"sponsored-label__text"},[_vm._v("Patrocinado")]),_c('i',{staticClass:"mdi mdi-information-outline"})]):_vm._e(),(_vm.item.embeantext)?_c('div',{staticClass:"codigo headInfoContent",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.item.embeantext)+" "),(
            _vm.item.qtdMinimaAtivacao > 0 && _vm.quantidade < _vm.item.qtdMinimaAtivacao
          )?_c('Alert',{attrs:{"type-alert":'alertDanger',"description":_vm.getQtdMinimaAtivacaoCampanha()}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"titulo-produto"},[_c('router-link',{attrs:{"tag":"a","to":{
            name: 'produtoRota',
            params: {
              codigo: _vm.item.integracao_id,
              id: _vm.item.cod_produto,
              filtroId: _vm.$route.query.filtroId,
            },
          },"title":_vm.item.descricao},nativeOn:{"click":function($event){return _vm.closeOverlay.apply(null, arguments)}}},[_c('span',{staticClass:"titulo",staticStyle:{"-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(_vm.toTitleCase(_vm.item.descricao)))]),_c('div',{staticClass:"descricao"})]),(_vm.isAuthenticated && _vm.dadosPedido && _vm.item.hascasadinha)?_c('div',{staticClass:"casadinha"},[_c('a',{on:{"click":_vm.handleCasadinha}},[_c('i',{staticClass:"fa fa-gift"})])]):_vm._e()],1),_c('div',{staticClass:"container-footer"},[(_vm.isAuthenticated && _vm.dadosPedido && _vm.isAvailable)?_c('div',{staticClass:"produto-price-container"},[_c('div',{staticClass:"price-item"},[(_vm.item.vlrdesconto > 0 || _vm.item.vlrstdesonerado > 0)?_c('strike',{staticClass:"price-old"},[_c('span',[_vm._v("De R$ "+_vm._s(_vm.mascaraValor(_vm.item.valor + _vm.item.vlrst))+" por")])]):_vm._e(),_c('div',{staticClass:"price-default"},[_c('span',{staticClass:"price-value"},[_vm._v("R$ "+_vm._s(_vm.mascaraValor(_vm.item.vlrbruto)))])])],1),(_vm.isAuthenticated && _vm.dadosPedido && _vm.isAvailable)?_c('ContainerPrice',{attrs:{"st":_vm.mascaraValor(_vm.item.vlrst / _vm.item.embqtdean, 4),"produto":_vm.mascaraValor((_vm.item.vlrbruto - _vm.item.vlrst) / _vm.item.embqtdean, 4),"desconto":_vm.mascaraValor(_vm.item.vlrdesconto / _vm.item.embqtdean, 4),"stdesonerado":_vm.mascaraValor(_vm.item.vlrstdesonerado / _vm.item.embqtdean, 4),"embalagem":_vm.embText(),"embvenda":_vm.item.embeantext,"total-unitario":_vm.mascaraValor(_vm.item.vlrbruto / _vm.item.embqtdean, 4)}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"footer-item flex flex-col justify-between"},[(
              _vm.isAuthenticated &&
              _vm.dadosPedido &&
              _vm.isAvailable &&
              _vm.productsWithCampanha[_vm.item.integracao_id] &&
              _vm.getCurrentRouter()
            )?_c('a',{staticClass:"promocao flex flex-row items-center justify-between",on:{"click":function($event){return _vm.handlePromocaoCampanha(
                _vm.productsWithCampanha[_vm.item.integracao_id].paramsUrl
              )}}},[_c('figure',{staticClass:"icon-container",attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"promo-icon",attrs:{"width":"16","height":"17","viewBox":"0 0 16 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","fill":"#028d45","d":"M2.04388 3.07091L3.77432 11.47H13.8226L15.265 4.27064H14.1025L12.8392 10.27H4.75229L3.76349 5.47064L3.51626 4.27064L3.02185 1.87091H0.734863V3.07091H2.04388ZM4.93086 14.4697C5.59361 14.4697 6.13086 13.9324 6.13086 13.2697C6.13086 12.607 5.59361 12.0697 4.93086 12.0697C4.26812 12.0697 3.73086 12.607 3.73086 13.2697C3.73086 13.9324 4.26812 14.4697 4.93086 14.4697ZM12.7289 14.4697C13.3916 14.4697 13.9289 13.9324 13.9289 13.2697C13.9289 12.607 13.3916 12.0697 12.7289 12.0697C12.0661 12.0697 11.5289 12.607 11.5289 13.2697C11.5289 13.9324 12.0661 14.4697 12.7289 14.4697ZM6.53383 8.65687L12.1338 3.05687L11.2853 2.20834L5.6853 7.80834L6.53383 8.65687ZM6.73378 4.25697C7.28607 4.25697 7.73378 3.80925 7.73378 3.25697C7.73378 2.70468 7.28607 2.25697 6.73378 2.25697C6.1815 2.25697 5.73378 2.70468 5.73378 3.25697C5.73378 3.80925 6.1815 4.25697 6.73378 4.25697ZM11.9337 7.47023C11.9337 8.02251 11.486 8.47023 10.9337 8.47023C10.3814 8.47023 9.93373 8.02251 9.93373 7.47023C9.93373 6.91794 10.3814 6.47023 10.9337 6.47023C11.486 6.47023 11.9337 6.91794 11.9337 7.47023Z"}})])]),_c('div',[(
                  _vm.productsWithCampanha[_vm.item.integracao_id].displayDiscount
                )?_c('p',{staticClass:"text-promotion"},[_vm._v(" Até "+_vm._s(_vm.productsWithCampanha[_vm.item.integracao_id].displayDiscount)+" OFF levando mais produtos participantes de uma promoção ")]):(
                  _vm.productsWithCampanha[_vm.item.integracao_id].tpDesconto == 0
                )?_c('p',{staticClass:"text-promotion"},[_vm._v(" Até "+_vm._s(`R$${parseFloat( _vm.productsWithCampanha[_vm.item.integracao_id].maxDesconto )} `)+" OFF levando mais produtos participantes de uma promoção ")]):_c('p',{staticClass:"text-promotion"},[_vm._v(" Até "+_vm._s(`${parseFloat( _vm.productsWithCampanha[_vm.item.integracao_id].maxDesconto )}% `)+" OFF levando mais produtos participantes de uma promoção ")])])]):_vm._e(),(_vm.isAuthenticated && _vm.dadosPedido)?_c('div',{staticClass:"qtd-container"},[(_vm.isAvailable)?void 0:(_vm.item.hascondbloq)?_c('div',[_c('Alert',{attrs:{"description":'Produto indisponivel para os parametros selecionados'}}),_c('router-link',{staticStyle:{"text-align":"center","font-size":"89%","color":"#001f60","cursor":"pointer"},attrs:{"tag":"div","to":{ name: 'catalogoRota' }},nativeOn:{"click":function($event){return _vm.closeOverlay.apply(null, arguments)}}},[_c('span',[_vm._v("Clique aqui para ver os itens disponiveis")])])],1):(!_vm.loadingProducts)?_c('Alert',{staticStyle:{"padding":"7px","font-size":"14px"},attrs:{"description":'Indisponível'}}):_vm._e()],2):_vm._e()])]),_c('div',{staticClass:"specsProduto"},[(_vm.showItemQtyBtn)?[((!_vm.isAuthenticated || _vm.isAvailable) && _vm.hasVariations)?_c('div',[_c('router-link',{staticClass:"btn-addItem",attrs:{"tag":"button","to":{
                name: 'produtoRota',
                params: {
                  codigo: _vm.item.integracao_id,
                  id: _vm.item.cod_produto,
                  filtroId: _vm.$route.query.filtroId,
                },
              }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Opções disponíveis ")])],1):(_vm.isAvailable && _vm.isAuthenticated && _vm.dadosPedido)?_c('item-qty-btn',{staticClass:"qtd-subContainer",attrs:{"item":_vm.item,"round-type":true}}):(_vm.dadosPedido == false || !_vm.isAuthenticated)?[_c('div',{staticClass:"qtd-subContainer"},[_c('button',{staticClass:"btn-buy",attrs:{"title":"Adicionar ao carrinho"},on:{"click":_vm.handleBuyClick}},[_vm._v(" Ver Preço ")])])]:_vm._e()]:_vm._e(),(!_vm.hasVariations)?_c('div',[_c('div',{staticClass:"codigo"},[_vm._v(" Cod.: "+_vm._s(_vm.item.integracao_id)),(_vm.userId && false)?[_vm._v(" -"+_vm._s(_vm.userId)+" ")]:_vm._e()],2),_c('div',{staticClass:"codigo"},[_vm._v("EAN: "+_vm._s(_vm.item.ean))])]):_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }