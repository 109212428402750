<template>
  <div
    :data-campanha="campanha.id"
    class="accordion-item"
    :class="{ 'accordion-active': campanha.active }"
  >
    <div class="accordion-header">
      <a class="accordion-header-button" @click="expand($event)">
        <div class="right-box">
          <IconMaxDesconto
            :grupos-bonus="campanha.grupos_bonus"
            :display-discount="campanha.display_max_discount"
          />
          <div class="accordion-header-div">
            <div class="accordion-header-label">
              <span class="description">{{ campanha.descricao }}</span>

              <div class="campaign-validity">
                {{ `Campanha válida até ${parseDateTime(campanha.dtfim)}  ` }}
                <span class="separator">-</span>
                <span
                  v-if="campanha.modelo_desconto == 'acumulado'"
                  class="campaign-discount-info"
                >
                  Esta oferta é cumulativa
                  <TooltipBalloon
                    :text="'Caso um produto participante desta oferta, seja ativado em um duas ou mais ofertas, os descontos irão acumular (somar), respeitando a regra de cada oferta.'"
                  >
                    <alert-circle-icon size="1.5x" class="custom-class" />
                  </TooltipBalloon>
                </span>
                <span v-else class="campaign-discount-info">
                  Esta oferta não acumula
                  <TooltipBalloon
                    :text="'Caso um produto participante desta oferta, seja ativado em duas ou mais ofertas, irá prevalecer o maior desconto entre as ofertas.'"
                  >
                    <alert-circle-icon size="1.5x" class="custom-class" />
                  </TooltipBalloon>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-header-div">
          <div class="accordion-caret"></div>
        </div>
      </a>
    </div>

    <div :ref="'accordion-body'" class="accordion-body">
      <div v-if="campanha.active" class="accordion-content">
        <div class="headerContent" :class="1 ? 'noBanner' : ''">
          <CardBannerHeader :campanha="campanha" />
          <div class="description">
            <div v-if="objetoImagens.length > 0">
              <img
                v-if="isMobile"
                :src="getMobileImageUrl"
                alt="Imagem Mobile"
              />
              <img v-else :src="getDesktopImageUrl" alt="Imagem Desktop" />
            </div>
            <h4>Descrição:</h4>
            <span>
              <span>
                Ganhe até {{ campanha.display_max_discount }} de desconto em
                itens selecionados, completando os combos de produtos abaixo no
                período de {{ parseDateTime(campanha.dtinicio) }} até
                {{ parseDateTime(campanha.dtfim) }}. Depois é só visualizar os
                produtos participantes da promoção e adicionar eles ao seu
                carrinho para garantir seu desconto. Mensalmente, você terá uma
                quantidade máxima de
                {{ campanha.qtd_max_prod_participantes }} produtos, e um valor
                máximo de
                {{
                  "R$ " + mascaraValor(campanha.valor_max_prod_participantes)
                }}
                nos produtos participantes.
              </span>
            </span>
          </div>
        </div>
        <div class="bodyContent">
          <div v-if="campanha.active" class="combosAtivacao">
            <ComboAtivacaoCard
              v-for="(comboAtivacao, index) in campanha.grupos_ativacao"
              :key="index"
              :index="index + 1"
              :combo-ativacao="comboAtivacao"
              :campanha-id="campanha.id"
            />
          </div>

          <div v-if="campanha.active" class="produtosBonusContent">
            <ProdutosBonusCard :campanha="campanha" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AlertCircleIcon from "vue-feather-icons/icons/AlertCircleIcon";
import gsap from "gsap";
import { format } from "date-fns";
import CardValorMinimo from "./CardValorMinimo.vue";
import { mascaraValor } from "@/app/global.js";
import ComboStage from "@/components/combo-campanhas/ComboStage.vue";
import IconMaxDesconto from "@/components/campanhas/IconMaxDesconto.vue";
import SliderCampanha from "@/components/combo-campanhas/SliderCampanha";
import ComboAtivacaoCard from "./ComboAtivacaoCard.vue";
import ProdutosBonusCard from "./ProdutosBonusCard.vue";
import TooltipBalloon from "@/components/tooltip-balloon/TooltipBalloon.vue";
import CardBannerHeader from "./components/CardBannerHeader/CardBannerHeader.vue";

export default {
  name: "CampanhaAccordion",
  components: {
    ComboStage,
    IconMaxDesconto,
    SliderCampanha,
    ComboAtivacaoCard,
    ProdutosBonusCard,
    CardValorMinimo,
    AlertCircleIcon,
    TooltipBalloon,
    CardBannerHeader,
  },
  props: {
    campanha: {
      type: Object,
    },
    active: { type: Number },
    handleAccordionActive: {},
  },
  data() {
    return {
      objetoImagens: null,
      showContent: false,
      produtosBonusContent: [],
      gruposAtivacao: [],
      isMobile: null,
    };
  },
  computed: {
    watchActive() {
      return this.active;
    },
    getMobileImageUrl() {
      var mobileImage = this.objetoImagens.find(
        (imagem) => imagem.tipo === "M"
      );
      if (!mobileImage) {
        var desktopImage = this.objetoImagens.find(
          (imagem) => imagem.tipo === "D"
        );
      }
      return mobileImage ? mobileImage.src : desktopImage.src;
    },
    getDesktopImageUrl() {
      var desktopImage = this.objetoImagens.find(
        (imagem) => imagem.tipo === "D"
      );
      return desktopImage.src;
    },
  },
  watch: {
    watchActive(newValue) {
      if (newValue != this.campanha.id) {
        this.handleExpand();
      }
    },
  },
  mounted() {
    // this.getValueProducts();
    this.handleExpand();
    this.objetoImagens = this.campanha.promocao_imagem;
    window.addEventListener("resize", this.tamanhoTela);
    this.isMobile = window.innerWidth < 768;
  },
  methods: {
    tamanhoTela() {
      this.isMobile = window.innerWidth < 768;
    },
    mascaraValor,
    getMaxDiscount(bonus = []) {
      let discount = 0;
      let tpDesconto = 1;
      if (bonus.length > 0) {
        bonus.forEach((bonusItem) => {
          if (parseFloat(bonusItem.vlrdesconto) > discount) {
            discount = parseFloat(bonusItem.vlrdesconto);
            tpDesconto = bonusItem.tpdesconto;
          }
        });
      }

      if (tpDesconto == 1) {
        discount = `${discount}%`;
      } else {
        discount = `R$ ${mascaraValor(discount)}`;
      }
      return discount;
    },
    handleExpand() {
      this.campanha.active = false;
      const el = this.$refs["accordion-body"];

      //! todo implementar função nativa para definir posição da página de acordo com o accordion aberto.
      gsap.to(el, {
        height: 0,
        duration: 2,
        ease: "sine",
      });
    },
    expand(e) {
      const el = this.$refs["accordion-body"];
      if (!this.campanha.active) {
        this.campanha.active = true;

        //! todo implementar função nativa para definir posição da página de acordo com o accordion aberto.
        gsap.to(el, {
          height: "100%",
          duration: 2,
          ease: "elastic(1, 0.3)",
        });

        const contentAccordion = document.querySelector(".accordion-content");

        if (contentAccordion) {
          const bodyRect = document.body.getBoundingClientRect();
          const elemRect = contentAccordion.getBoundingClientRect();
          const offset = elemRect.top - bodyRect.top;

          window.scrollTo({
            top: offset,
            behavior: "smooth",
          });
        }

        this.$store.dispatch("setCampanhaStatus");
        this.handleAccordionActive(this.campanha.id);
      } else {
        this.campanha.active = false;
        //! todo implementar função nativa para definir posição da página de acordo com o accordion aberto.
        gsap.to(el, {
          duration: 2,
          height: 0,
          ease: "sine",
        });
      }
    },
    parseDateTime(date, type = "date") {
      if (type == "date") {
        return format(new Date(date), "dd/MM/yy");
      }
      // return format(parse(date, "hh:mm:ss"), "hh:mm:ss");
      return format(new Date(date), "hh:mm");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/global.css";
$base-color: #727272;
$base-width: 100%;
$base-time: 200ms;

$app-background-color: #82dfff;
$app-padding: 2rem;
$accordion-item-background-color: #fff;
$accordion-item-border-radius: 6px;
$accordion-item-margin-bottom: 50px;

$accordion-background-color: transparent;
$accordion-max-width: $base-width;
$accordion-padding: 0;
$accordion-border-radius: 0.5rem;
$accordion-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);

$accordion-header-font-size: 1.2rem;
$accordion-header-height: 2rem;
$accordion-header-padding: 1rem 1rem 1rem 1rem;
$accordion-header-border-radius: 0.6rem;
$accordion-header-transition: background-color $base-time ease-in-out;

$accordion-header-hover-background-color: #efefef;

$accordion-caret-color: $base-color;
$accordion-caret-background-image: linear-gradient(
  to top right,
  transparent 50%,
  $accordion-caret-color 50%
);
$accordion-caret-width: 0.7rem;
$accordion-caret-height: 0.7rem;
$accordion-caret-transform: rotate(-45deg);
$accordion-caret-transforming: scale(1.5) rotate(45deg);
$accordion-caret-active-transform: rotate(135deg);

$accordion-body-content-padding: 0px 20px 20px 20px;

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");

.accordion-header-button {
  cursor: pointer;
  padding: 20px;
}
.accordion-item {
  background-color: $accordion-background-color;
  width: 100%;
  max-height: 100%;
  max-width: $accordion-max-width;
  padding: $accordion-padding;
  margin: auto;
  box-sizing: border-box;
  transition: $accordion-header-transition;
  overflow: visible;
  border-radius: 6px;
  margin-bottom: $accordion-item-margin-bottom;
  box-shadow: $accordion-box-shadow;
  background-color: $accordion-item-background-color;
  border-radius: $accordion-item-border-radius;
  font-family: "Montserrat";
}

.accordion-item.accordion-active {
  .accordion-caret {
    animation: accordion-is-active $base-time linear forwards;
  }
}

.accordion-header {
  color: inherit;
  font-size: $accordion-header-font-size;
  font-weight: bold;
  position: relative;
  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    border-radius: $accordion-header-border-radius;
  }
}

.right-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  // width: 60%;
}

.accordion-header-label {
  display: flex;
  flex-direction: column;
}
.accordion-header-label .description {
  color: #303932;
  font-size: 16px;
  text-transform: capitalize;
}
.accordion-header-label .campaign-validity {
  font-weight: normal;
  color: #979797;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;

  .campaign-discount-info {
    display: flex;
    align-items: center;
    gap: 3px;
    svg:hover {
      transition: 0.3s;
      color: #303932;
    }
  }
}

.accordion-header-div {
  padding: $accordion-header-padding;
  margin-left: 20px;
  &:last-child {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.accordion-caret {
  background-image: $accordion-caret-background-image;
  width: $accordion-caret-width;
  height: $accordion-caret-height;
  transform: $accordion-caret-transform;
  animation: accordion-is-inactive $base-time linear forwards;
}

.accordion-body {
  height: 0;
  // overflow: hidden;
  width: 97%;
  margin: 0 auto;
}

.accordion-content {
  .headerContent {
    .description {
      margin: 20px 0;
      img {
        max-width: 100%;
        padding-bottom: 20px;
      }
      h4 {
        margin: 0;
        margin-bottom: 10px;
        color: #303932;
        font-weight: bold;
      }
      span {
        color: #979797;
        font-size: 13px;
      }
    }
  }
  .noBanner {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .description {
      grid-column: 1;
      grid-row: 1;
    }
    .headContentDetails {
      grid-column: 2;
    }
  }
}
@media (max-width: 768px) {
  .accordion-body {
    .rules {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
    }
    .accordion-content {
      .noBanner {
        display: initial;
      }
      p {
        width: 100%;
      }
    }
  }
}

@media (max-width: 650px) {
  .campaign-validity {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
    gap: 0;

    span.separator {
      display: none;
    }
  }
}

@keyframes accordion-is-inactive {
  0% {
    transform: $accordion-caret-transform;
  }
  50% {
    transform: $accordion-caret-transforming;
  }
  100% {
    transform: $accordion-caret-active-transform;
  }
}

@keyframes accordion-is-active {
  0% {
    transform: $accordion-caret-active-transform;
  }
  50% {
    transform: $accordion-caret-transforming;
  }
  100% {
    transform: $accordion-caret-transform;
  }
}

@media screen and (min-width: $base-width) {
  #app {
    padding: $app-padding;
    overflow: auto;
  }

  .accordion {
    max-height: none;
    border-radius: $accordion-border-radius;
  }
}
</style>
