var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-product-item",class:{ 'form-shake-error': !_vm.item.status || _vm.itemHasNoStock }},[_c('router-link',{staticClass:"item-img",class:{ inactive: !_vm.item.status },attrs:{"to":{
      name: 'produtoRota',
      params: { codigo: _vm.item.integracao_id },
    }}},[_c('ImageItem',{attrs:{"custom-class":"img-responsive","source":_vm.item.src,"description":_vm.item.descricao}})],1),_c('div',{staticClass:"item-info"},[(_vm.item.descricao)?_c('router-link',{attrs:{"to":{
        name: 'produtoRota',
        params: { codigo: _vm.item.integracao_id },
      }}},[_vm._v(_vm._s(_vm.toTitleCase(_vm.item.descricao)))]):_vm._e(),_c('div',{staticClass:"item-price"},[_c('span',{staticClass:"price-each"},[_vm._v(" Código: "),_c('b',[_vm._v(_vm._s(_vm.item.integracao_id))])]),_c('span',{staticClass:"price-each"},[_vm._v(" Qtd.: "),_c('b',[_vm._v(_vm._s(_vm.item.qtd)+" x R$ "+_vm._s(_vm.mascaraValor( _vm.item.valor - _vm.item.vlrdesconto - _vm.item.vlrstdesonerado + _vm.item.vlrst )))]),(_vm.item.vlrdesconto > 0 || _vm.item.vlrstdesonerado)?_c('strike',{staticClass:"price-old"},[_vm._v("(R$ "+_vm._s(_vm.mascaraValor(_vm.item.valor + _vm.item.vlrst))+")")]):_vm._e()],1),_c('h5',{staticClass:"price-total"},[_vm._v(" R$ "+_vm._s(_vm.mascaraValor( (_vm.item.valor - _vm.item.vlrdesconto - _vm.item.vlrstdesonerado + _vm.item.vlrst) * _vm.item.qtd ))+" "),(_vm.item.vlrdesconto > 0 || _vm.item.vlrstdesonerado > 0)?_c('strike',{staticClass:"price-old"},[_vm._v(" (R$ "+_vm._s(_vm.mascaraValor((_vm.item.valor + _vm.item.vlrst) * _vm.item.qtd))+") ")]):_vm._e()],1)]),(_vm.itemHasNoStock)?_c('small',{staticClass:"text-warning"},[_vm._v(" Reduzimos a quantidade para "),_c('b',[_vm._v(_vm._s(_vm.item.qtdestoque))]),_vm._v(", pois o número solicitado não está disponível. ")]):_vm._e(),(_vm.item.haspromocaocampanha)?_c('small',{staticClass:"text-promotion"},[_vm._v(" Este produto faz parte de uma campanha ")]):_vm._e(),(!_vm.item.status)?_c('small',{staticClass:"text-danger"},[_vm._v(" Esse produto está indisponível para seus parâmetros de compra, para finalizar o pedido, remova-o do carrinho ")]):_vm._e()],1),(_vm.canChangeQty)?_c('item-qty-btn',{attrs:{"simple":true,"removeitem":!_vm.item.status,"item":_vm.item,"can-remove-all":true,"is-cart-or-checkout":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }