<template>
  <div>
    <!-- <ToolbarCallCenter></ToolbarCallCenter> -->
    <footer class="footer">
      <div class="container info-gerais">
        <section class="subContainerSec">
          <div class="col-sm-12">
            <!-- <div class="col-xs-12 col-md-3 col-sm-3">
              <section>
                <!-- <h5>
                  <strong>Televendas</strong>
                </h5>
                <ul>
                  <li>
                    <strong>Call Center</strong>
                  </li>
                  <li>
                    <h4 class="fone">
                      <a href="tel://0800-285-0005">0800-285-0005</a>
                    </h4>
                  </li>
                  <li>
                    <h4 class="fone">
                      <a href="tel://0800-590-3000">0800-590-3000</a>
                    </h4>
                  </li>
                  <li>
                    <h4 class="fone">
                      <a href="tel://(33) 3329-0014">(33) 3329-0014</a>
                    </h4>
                  </li>
                </ul> -->
            <!-- <br />
                <div class="text-block">
                  <div v-if="getTelHrDiaUtilInicio && getTelHrDiaUtilFim">
                    <strong>Horário de funcionamento:</strong>
                    <br />Segunda a sexta-feira
                    <br />
                    {{ getTelHrDiaUtilInicio }} às {{ getTelHrDiaUtilFim }}
                  </div>
                  <div v-if="getTelSabadoInicio && getTelSabadoFim">
                    Sábados
                    <br />
                    {{ getTelSabadoInicio }} às {{ getTelSabadoFim }}
                  </div>
                  <div v-if="getTelDomingoInicio && getTelDomingoFim">
                    Domingos
                    <br />
                    {{ getTelDomingoInicio }} às {{ getTelDomingoFim }}
                  </div>
                </div> --
              </section>
            </div> -->
            <div class="col-xs-12 col-md-4 col-sm-4">
              <section>
                <h5>
                  <strong>Sobre a NSG</strong>
                </h5>
                <!--<ul>
                  <li>
                    <strong>
                      <a href="https://drlmg.com.br/institucional/"
                        >Quem somos</a
                      >
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a href="https://drlmg.com.br/institucional/home/#duvidas"
                        >D&uacute;vidas Frequentes</a
                      >
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a
                        href="https://drlmg.com.br/institucional/politica-de-privacidade/"
                        >Pol&iacute;tica de Privacidade</a
                      >
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a href="https://drlmg.com.br/institucional/home/#duvidas"
                        >Trocas e devolu&ccedil;&otilde;es</a
                      >
                    </strong>
                  </li>
                </ul>-->

                <!-- <div class="text-block">
                  <div v-if="getSacHrDiaUtilInicio && getSacHrDiaUtilFim">
                    <strong>Horário de funcionamento:</strong>
                    <br />Segunda a sexta-feira
                    <br />
                    {{ getSacHrDiaUtilInicio }} às {{ getSacHrDiaUtilFim }}
                  </div>
                  <div v-if="getSacSabadoInicio && getSacSabadoFim">
                    Sábados
                    <br />
                    {{ getSacSabadoInicio }} às {{ getSacSabadoFim }}
                  </div>
                  <div v-if="getSacDomingoInicio && getSacDomingoFim">
                    Domingos
                    <br />
                    {{ getSacDomingoInicio }} às {{ getSacDomingoFim }}
                  </div>
                </div>
                <br />
                <ul>
                  <li>
                    <strong>SAC</strong>
                  </li>
                  <li>
                    <h4 class="fone">
                      <a href="tel://0800-283-0000">0800-283-0000</a>
                    </h4>
                  </li>
                  <li>
                    <h4 class="fone">
                      <a href="tel://(33) 3329-0046">(33) 3329-0046</a>
                    </h4>
                  </li>
                </ul> -->
                <!-- <br /> -->
                <!-- <div class="text-block">
                  <div v-if="getSacHrDiaUtilInicio && getSacHrDiaUtilFim">
                    <strong>Horário de funcionamento:</strong>
                    <br />Segunda a sexta-feira
                    <br />
                    {{ getSacHrDiaUtilInicio }} às {{ getSacHrDiaUtilFim }}
                  </div>
                  <div v-if="getSacSabadoInicio && getSacSabadoFim">
                    Sábados
                    <br />
                    {{ getSacSabadoInicio }} às {{ getSacSabadoFim }}
                  </div>
                  <div v-if="getSacDomingoInicio && getSacDomingoFim">
                    Domingos
                    <br />
                    {{ getSacDomingoInicio }} às {{ getSacDomingoFim }}
                  </div>
                </div> -->
              </section>
            </div>
            <!-- <div class="col-xs-12 col-md-3 col-sm-3">
              <section>
                <h5>
                  <strong>Meus pedidos</strong>
                </h5>
                <ul>
                  <li>
                    <router-link to="/minha-conta/pedidos" tag="a">
                      <strong>Acompanhe seus pedidos</strong>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/minha-conta/titulos" tag="a">
                      <strong>Segunda via de boletos</strong>
                    </router-link>
                  </li>
                </ul>

                <h5>
                  <strong>Métodos de pagamento</strong>
                </h5>
                <ul>
                  <li>
                    <img
                      src="~@/assets/img/payment-methods.png"
                      alt=""
                      width="185"
                    />
                  </li>
                </ul>
              </section>
            </div> -->

            <div class="col-xs-12 col-md-4 col-sm-4">
              <section>
                <h5>
                  <strong>Minha conta</strong>
                </h5>
                <ul>
                  <li>
                    <router-link to="/cadastro" tag="a">
                      <strong>Criar conta</strong>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/minha-conta/overview" tag="a">
                      <strong>Minha conta</strong>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/minha-conta/pedidos" tag="a">
                      <strong>Meus pedidos</strong>
                    </router-link>
                  </li>
                  <!-- <li>
                  <router-link to="/atendimento" tag="a">
                    <strong>Onde estamos</strong>
                  </router-link>
                </li> -->
                  <li v-if="showTrabalheConosco">
                    <router-link to="/trabalhe-conosco" tag="a">
                      <strong>Trabalhe conosco</strong>
                    </router-link>
                  </li>
                </ul>
                <!-- <h5>
                  <strong>Serviços</strong>
                </h5>
                <ul>
                  <li>
                    <a target="_self" href="https://webmail.dpcnet.com.br/">
                      <strong>Webmail</strong>
                    </a>
                  </li>
                </ul> -->
              </section>
            </div>
            <div class="col-xs-12 col-md-4 col-sm-4">
              <section>
                <h5>
                  <strong>Ajuda</strong>
                </h5>
                <ul>
                  <!-- <li>
                    <router-link to="/atendimento" tag="a">
                      <strong>Dúvidas frequentes</strong>
                    </router-link>
                  </li> -->
                  <li>
                    <router-link to="/atendimento" tag="a">
                      <strong>Fale Conosco</strong>
                    </router-link>
                  </li>
                </ul>
              </section>
            </div>
          </div>

          <br /><br />

          <div class="col-sm-12 col-md-3 logo">
            <section class="logo-section">
              <div class="right-side">
                <!-- <div class="sb-seal">
                  <div id="armored_website">
                    <param id="aw_preload" value="true" />
                    <param id="aw_use_cdn" value="true" />
                  </div>
                </div>-->
              </div>
              <div class="img-logo-box">
                <img
                  v-if="logoUrl"
                  loading="lazy"
                  :src="logoUrl"
                  class="img-logo"
                />
                <img
                  v-else
                  loading="lazy"
                  src="~@/assets/img/no-logo.svg"
                  class="img-logo"
                />

                <!-- <h5><strong>Quem Somos</strong></h5> -->
                <!--<strong
                  ><p class="footer-text">
                    A distribuidora parceira do seu neg&oacute;cio<br />
                    em Minas Gerais
                  </p></strong
                >-->
              </div>

              <!--<router-link to="/institucional" tag="a" class="btn btn-info-inverse">Saiba Mais</router-link>-->
              <section class="section-social">
                <a target="_blank" rel="noopener" :href="social.facebook">
                  <i
                    class="fa fa-facebook social-icon fa-2"
                    aria-hidden="true"
                  ></i>
                </a>
                <a target="_blank" rel="noopener" :href="social.instagram">
                  <i
                    class="fa fa-instagram social-icon fa-2"
                    aria-hidden="true"
                  ></i>
                </a>
                <a target="_blank" rel="noopener" :href="social.linkedin">
                  <i
                    class="fa fa-linkedin social-icon fa-2"
                    aria-hidden="true"
                  ></i>
                </a>
                <strong
                  ><p class="footer-text">
                    Acompanhe a {{ appNameShort }} nas redes sociais
                  </p></strong
                >
              </section>
            </section>
          </div>
        </section>
      </div>
      <div class="container footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-12 dados-empresa">
              <strong
                ><p class="text-center">
                  © Copyright {{ appNameShort }} - Todos os direitos reservados.
                </p></strong
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import * as global from "@/app/global";
import ToolbarCallCenter from "@/components/toolbar-callcenter/ToolbarCallCenter.vue";

export default {
  name: "Footer",
  components: {
    ToolbarCallCenter,
  },
  data() {
    return {
      social: {
        facebook: "",
        instagram: "",
        linkedin:
          "https://www.linkedin.com/company/",
      },
      logoUrl: this.$store.getters.siteConfig.app_logo,
      appNameShort: this.$store.getters.siteConfig.app_name_short,
      showTrabalheConosco:
        process.env.VUE_APP_STORE_SHOW_TABALHE_CONOSCO == "true",
    };
  },
  computed: {
    getTelTelevenda() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_televenda
      );
    },
    getTelSac() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_sac
      );
    },
    getFaleConosco() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.fale_conosco
      );
    },
    getTelHrDiaUtilInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_dia_util_inicio
      );
    },
    getTelHrDiaUtilFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_dia_util_fim
      );
    },
    getTelSabadoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_sabado_inicio
      );
    },
    getTelSabadoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_sabado_fim
      );
    },
    getTelDomingoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_domingo_inicio
      );
    },
    getTelDomingoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_domingo_fim
      );
    },
    getSacHrDiaUtilInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_dia_util_inicio
      );
    },
    getSacHrDiaUtilFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_dia_util_fim
      );
    },
    getSacSabadoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_sabado_inicio
      );
    },
    getSacSabadoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_sabado_fim
      );
    },
    getSacDomingoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_domingo_inicio
      );
    },
    getSacDomingoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_domingo_fim
      );
    },
  },
  mounted() {
    let script = document.createElement("script");
    // script.onload = () => {
    // }
    script.src = "//cdn.siteblindado.com/aw.js";
    document.head.appendChild(script);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.img-logo-box {
  justify-self: center;
  width: 30%;
}
.right-side {
  width: 25%;
}
.section-social {
  width: 25%;
}
.subContainerSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.logo-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.sb-seal {
  background-color: white;
  border-radius: 6.5px;
  padding: 5px;
  width: fit-content;
}
.footer {
  bottom: 0;
  width: 100%;
  padding-top: 35px;
  background-color: var(--brand-secondary, $brand-secondary);
  color: #fff;
  font-size: 0.95em;
  h5 {
    font-weight: 600;
    color: var(--brand-accent, $brand-accent);
    font-size: 1.3em;
  }
  .social-icon {
    background-color: #fff;
    border: 01px solid var(--brand-primary, $brand-primary);
    color: var(--brand-primary, $brand-primary);
    padding: 0;
    border-radius: 50px;
    font-size: 1.4em;
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    line-height: 31px;
    margin: 0;
    &:hover {
      background-color: var(--brand-primary, $brand-primary);
      color: #fff;
      border: 01px solid #fff;
    }
    &:before {
      top: 2px;
      position: relative;
    }
  }
}
.footer-bottom {
  padding-top: 15px;
  padding-bottom: 5px;
}
.col-sm-12.logo {
  text-align: center;
  padding-top: 25px;
}
.logo {
  display: flex;
  width: 100%;
}
.img-logo {
  height: 40px;
  margin: 5px auto;
  display: flex;
}
.footer-text {
  padding-top: 10px;
  font-weight: 600;
  font-size: 1.05em;
}
.fone {
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 1.3em !important;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    a {
      color: #fff;
    }
  }
  .fa-angle-right {
    //font-size: 10px;
    margin-right: 4px;
    color: $brand-info;
  }
}
.dados-empresa {
  padding-left: 0;
}
@media (max-width: 768px) {
  .right-side {
    width: initial !important;
    margin-bottom: 25px;
  }
  .logo-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section-social {
    width: 100%;
    margin-top: 10px;
  }
  .img-logo-box {
    width: 50%;
  }
  .footer {
    text-align: center;
  }
}
@media (max-width: 620px) {
  .img-logo-box {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .footer {
    padding-top: 20px;
  }
  .footer-bottom {
    border: none;
    padding-top: 0px;
    p {
      text-align: center;
    }
  }
}
</style>
