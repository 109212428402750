<template>
  <div class="timelineContainer" :class="isFixed ? isFixed : ''">
    <div v-if="!todosGruposAtivados" class="comboTitleContent">
      <span>{{ grupoProximoAtivacao.name }}</span>
    </div>
    <div class="header">
      <div class="titleHead">
        <div class="iconDiscount">
          <i class="mdi mdi-sale-outline fa-3x"></i>
        </div>
        <h3>
          <span
            v-if="
              !todosGruposAtivados &&
              grupoProximoAtivacao.qtdMinProd -
                grupoProximoAtivacao.qtdMinProdAtivados >
                0
            "
          >
            {{
              `Adicione mais ${
                grupoProximoAtivacao.qtdMinProd -
                grupoProximoAtivacao.qtdMinProdAtivados
              } produtos para ativar o combo.`
            }}
          </span>
          <span
            v-else-if="
              !todosGruposAtivados &&
              grupoProximoAtivacao.vlrMinProd -
                grupoProximoAtivacao.vlrMinProdAtivados >
                0
            "
          >
            {{
              `Adicione mais R$ ${mascaraValor(
                grupoProximoAtivacao.vlrMinProd -
                  grupoProximoAtivacao.vlrMinProdAtivados
              )} em produtos para ativar o combo.
              `
            }}
          </span>
          <span v-else>
            Você ativou todos os combos! Aproveite os descontos :)
            <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
          </span>
        </h3>
      </div>

      <div v-if="!todosGruposAtivados" class="progressbar">
        <div class="bar">
          <div
            class="ballProgress"
            :class="percentAnimated == 100 ? 'ballNone' : ''"
            :style="{ left: percentAnimated - 0.5 + '%' }"
          ></div>
          <div
            class="line line-progressive"
            :style="{ width: percentAnimated + '%' }"
          ></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertCircleIcon from "vue-feather-icons/icons/AlertCircleIcon";
import gsap from "gsap";
import { mascaraValor } from "@/app/global.js";
import TimelineItem from "./TimelineItem.vue";

export default {
  name: "Timeline",
  components: { AlertCircleIcon, TimelineItem },
  props: {
    campanha: {},
  },
  data() {
    return {
      show: false,
      maxDiscount: 0,
      timelineData: {},
      grupoProximoAtivacao: {},
      todosGruposAtivados: false,
      percentCampaign: 0,
      isFixed: "",
    };
  },
  computed: {
    discount() {
      const situacaoCredito = this.campanha.situacao_credito[0];
      return {
        min: situacaoCredito ? situacaoCredito.valor_restante : 0,
        max: situacaoCredito ? situacaoCredito.valor_maximo : 0,
      };
    },
    percentDiscountMax() {
      return this.getMaxDiscount();
    },
    campanhaStatus() {
      return this.$store.getters.campanhaStatus;
    },
    percentAnimated() {
      return this.percentCampaign;
    },
    maxMonthDiscount() {
      return this.$store.getters.campanhaStatus[
        this.campanha.id.toString()
      ].vlrRestanteCredito;
    },
  },

  watch: {
    campanhaStatus(newValue) {
      this.getDataTimeline();

      //! todo implementar função nativa para definir posição da página de acordo com o accordion aberto.
      gsap.to(this.$data, {
        duration: 1,
        percentCampaign: this.handlePercent(),
      });
    },
  },
  created() {
    window.addEventListener("mousewheel", this.handleScroll());
  },
  methods: {
    handleScroll() {
      window.onwheel = (e) => {
        try {
          const clientY = $(".discountContent").getBoundingClientRect().y;

          if (e.deltaY >= 0) {
            if (clientY <= 0) {
              this.isFixed = "isFixed scrollDown";
            } else {
              this.isFixed = false;
            }
          } else {
            if (clientY <= 0) {
              this.isFixed = "isFixed scrollUp";
            } else {
              this.isFixed = false;
            }
          }
        } catch (e) {
          //
        }
      };
    },
    mascaraValor,

    handlePercent() {
      return this.timelineData.percentAtual;
    },

    getDataTimeline() {
      const campanhaId = this.campanha.id.toString();
      const campanhaStatus = this.campanhaStatus[campanhaId];

      const idGrupoProximoAtivacao = campanhaStatus.grupoProximoAtivado.id;
      this.timelineData = campanhaStatus;

      if (idGrupoProximoAtivacao) {
        this.grupoProximoAtivacao =
          campanhaStatus.gruposAtivacaoStatus[idGrupoProximoAtivacao];
        this.todosGruposAtivados = false;
      } else {
        this.todosGruposAtivados = true;
      }
    },

    getMaxDiscount() {
      let discount = 0;

      let bonus = this.campanha.grupos_bonus;

      if (bonus.length > 0) {
        bonus.forEach((bonusItem) => {
          if (
            bonusItem.tpdesconto == 1 &&
            parseFloat(bonusItem.vlrdesconto) > discount
          ) {
            discount = `${mascaraValor(bonusItem.vlrdesconto)}%`;
          } else if (
            bonusItem.tpdesconto == 0 &&
            parseFloat(bonusItem.vlrdesconto) > discount
          ) {
            discount = `R$ ${mascaraValor(bonusItem.vlrdesconto)}`;
          }
        });
      }
      return discount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

$title: #303932;
$subTitle: #979797;

.timelineContainer {
  border-radius: 6px;
  padding: 15px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background: white;

  .comboTitleContent {
    position: relative;
    top: -16px;
    left: -16px;
    color: white;
    padding: 0 10px;
    align-self: self-start;
    background: var(--brand-primary, $brand-primary);
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;

    span {
      text-transform: capitalize;
    }
  }

  .header {
    width: 100%;
    .titleHead {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      h3 {
        margin: 0;
        font-size: 14px;
        line-height: 1.2;
        color: $title;
      }

      .iconDiscount {
        background: rgb(229, 229, 229);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        padding: 5px;
        height: 65px;
        border-radius: 5px;
        color: var(--brand-primary, $brand-primary);
      }
    }

    .progressbar {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .bar {
        .line {
          background: var(--brand-primary, $brand-primary);
          width: 100%;
          height: 8px;
          border-radius: 50px;
        }
        .line-progressive {
          transform: translateY(8px);
          background: var(--brand-secondary, $brand-secondary);
        }
        .ballProgress {
          width: 20px;
          height: 20px;
          background: var(--brand-primary, $brand-primary);
          border: solid 5px var(--brand-accent, $brand-accent);
          border-radius: 100%;
          transform: translateY(21px);
          transform: translateX();
          position: relative;
        }
        .ballNone {
          opacity: 0;
        }
      }
    }
  }
}

.isFixed {
  position: fixed;
  transition-property: top;
  transition-duration: 0.3s;
  box-shadow: 0 0 15px -1px #000000;
  z-index: 11;
  justify-self: flex-end;
  align-self: end;
  transform: translateX(40px);

  &.scrollDown {
    top: 70px;
  }
  &.scrollUp {
    top: 110px;
  }
}

@media (max-width: 1300px) {
  .timelineContainer {
    max-width: 400px;
  }
}

@media (max-width: 1128px) {
  .timelineContainer {
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  .timelineContainer {
    margin: 0;
  }
  .isFixed {
    justify-self: center;
    align-self: center;
    transform: translateX(0px);
    &.scrollDown {
      top: 120px;
    }
    &.scrollUp {
      top: 155px;
    }
  }
}
@media (max-width: 428px) {
  .timelineContainer {
    width: 95% !important;
    .titleHead {
      h3 {
        font-size: 1.3rem !important;
      }

      .iconDiscount {
        width: 45px !important;
        height: 45px !important;
      }
    }
    .dialog {
      width: 100%;
      // padding: 0px 15px;
    }
    .comboTitleContent {
      span {
        font-size: 11px;
      }
    }
  }
  .isFixed {
    &.scrollDown {
      top: 5px;
    }
    &.scrollUp {
      top: 145px;
    }
  }
}
@media (max-width: 340px) {
  .timelineContainer {
    .titleHead {
      text-align: center;
      .iconDiscount {
        display: none !important;
      }
    }
  }
  .isFixed {
    &.scrollUp {
      top: 185px;
    }
  }
}
</style>
